



import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Navbar from './Navbar';

const Kolkatta=()=>{
    let [coins,setcoins]=useState([]);
    // let [modal,setmodal]=useState(false);
    let [mintselected,setmint]=useState('k');
    const navigate=useNavigate();
    const clickhandler=(route)=>{
        navigate(`/${route}`)
    }

    useEffect(
        ()=>{
            const callmint=()=>{
                axios.get(`https://worker-frosty-wave-9d6c.akedarshenoy.workers.dev/api/mint`).then(result =>{
                // console.log("api output")
                let response =[];
                response=result.data;
                const get=(mintselected)=>{
                let obj={}
                const red= response.forEach((element)=>{
                    const arr2=[];
                    for(let i=0;i<element.store.length;i++){
                       switch (mintselected) {
                        case "m":
                            if(element.store[i].mint.m){
                                arr2.push( element.store[i].year)
                                }
                            break;             
                        case "h":
                                if(element.store[i].mint.h){
                                    arr2.push( element.store[i].year)
                                    }
                                break;
                        case "n":
                                if(element.store[i].mint.n){
                                    arr2.push( element.store[i].year)
                                }
                            break;
                        case "k":
                                if(element.store[i].mint.k){
                                    arr2.push( element.store[i].year)
                                    }
                                break;
                        default:
                            break;
                       }
                       
                            obj[element.coin]=arr2;
                    }
                    // console.log(obj)
                })
                setcoins(obj);
                // console.log(obj)
                return obj;
        
            }
            //  console.log(get(mintselected))
            get(mintselected)
            }).catch(eroor=>{
                console.log(eroor)
            })
            };
            callmint();
        const availabecoins =Object.keys(coins);

        },[]
        )
        const availabecoins =Object.keys(coins);


return <>
        <Navbar page={2}></Navbar>
        <div className="mainmintsdiv">

            <div  className="heading my-5 madimi-one-regular"><h1>Kolkata Mint </h1></div>


        </div>
        <div style={{textAlign: 'center', overflowX: 'auto'}}>
    <table style={{width: '100%', maxWidth: '800px', margin: '0 auto', borderCollapse: 'collapse'}}>
        <thead>
            <tr style={{backgroundColor: 'lightgray'}}>
                <th style={{border: '2px solid gray', padding: '10px', cursor: 'pointer', color: 'darkgoldenrod'}} onClick={() => navigate('/regular')}>Category</th>
                <th style={{border: '2px solid gray', padding: '10px', cursor: 'pointer', color: 'darkgoldenrod'}}>Year</th>
            </tr>
        </thead>
        <tbody>
            {availabecoins.map((item, index) => (
                <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'rgb(13, 16, 29)' : 'rgb(13, 16, 29)'}}>
                    <td style={{border: '2px solid gray', padding: '10px', color: 'darkgoldenrod', fontWeight: 'bold'}}>{item}</td>
                    <td style={{border: '2px solid gray', padding: '10px', color: 'gold'}}>
                        {coins[item].map((coin, idx) => (
                            <div key={idx} style={{padding: '5px'}}>
                                • {coin}
                            </div>
                        ))}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

</>
}

export default Kolkatta;