import axios from 'axios'
import { useEffect, useState } from 'react';

import Navbar from './Navbar';



const Specialcirculation =()=>{
        const [res,setres]=useState([]);
        useEffect(()=>{
                axios.get("https://coinapi.akedarshenoy.workers.dev/api/special").then(result =>{
                    setres(result.data)
                    // console.log(result.data);
                }).catch(error=>{
                    console.log(error)
                })
        },[])
    return(
        <>
                <Navbar page={2}></Navbar>

       
        <div className="container">
                <div className="row">
                <div  className="heading my-5 madimi-one-regular"><h1>Special Circulation 🏆</h1></div>
                    {
                        res.map((item,index)=>{
                            return <div className='col-lg-4 col-md-6 col-sm-6 col-xl-3 col-xs-12 coinbox' key={index}  >
                            <img src={`https://raw.githubusercontent.com/kedarshenoy/coinimages/master/coins3/${index+1}.jpg`} className="carousalimgmyc" alt="m" />
                            
                            {/* <img src={require(`../coins3/${index+1}.jpg`)} className="carousalimgmyc" alt="m" /> */}
                            <h4 className='coinname'>{item.coin}</h4>
                            <p className='coindesc' ><b>Mint : </b>{item.mint} <br/> <b>Year : </b>{item.year}</p>
                         </div>
                        })
                    }
                    
                   
    
                </div>
            </div>
        </>
    )
    }
    
    export default Specialcirculation;