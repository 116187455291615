import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import {useNavigate} from "react-router-dom"
import { Carousel } from 'react-responsive-carousel';
import '../Styles/home.css'


const Home = ()=>{
   const navigate= useNavigate();

   const Mycollections=()=>{
      navigate('/mycollections')
   }
    return (
        <>
        
        
        <div className="container">
            <div className="row">
                <div  className="heading my-5"><h1 className=" honk-coin">AKS Collections</h1></div>
                <div className="col-lg-6 col-sm-12 entrtytext" onClick={()=>{Mycollections()}}>

                <div >
                <h2 className="entryhead nabla-coin">A Personal Treasure Trove:</h2>
                <h3 className="entrysecondhead madimi-one-regular">Explore Kedar's Coin Collection</h3>
                <p className="entrypara madimi-one-regular">Together, let's unravel the mysteries and marvels encapsulated in the coins I've gathered over the years</p>
                <div className="btndiv my-4"  >
                  <span className="btn btn-danger firstbtn madimi-one-regular" onClick={()=>{Mycollections()}}> My Collections🔍</span>
                </div>
                </div>
                </div>
                <div className="col-lg-6 col-sm-12" onClick={()=>{Mycollections()}}>

                <Carousel  infiniteLoop={true} showThumbs={false} autoPlay={true} interval={2500}  showStatus={false} >
                    <div >
                        <img src={require("../homecoins/1.png")} className="carousalimg" alt="m" />
                     </div>
                    

                     <div >
                        <img src={require("../homecoins/2.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/3.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/4.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/5.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/6.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/7.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/8.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/9.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/10.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/11.png")} className="carousalimg" alt="m" />
                     </div>
                    

                     <div >
                        <img src={require("../homecoins/12.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/13.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/14.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/15.png")} className="carousalimg" alt="m" />
                     </div>
                    

                     <div >
                        <img src={require("../homecoins/16.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/17.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/18.png")} className="carousalimg" alt="m" />
                     </div>
                    

                     <div >
                        <img src={require("../homecoins/19.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/20.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/21.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/22.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/23.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/24.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/25.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/26.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/27.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/28.png")} className="carousalimg" alt="m" />
                     </div>
                    

                     <div >
                        <img src={require("../homecoins/29.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/30.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/31.png")} className="carousalimg" alt="m" />
                     </div>

                     <div >
                        <img src={require("../homecoins/32.png")} className="carousalimg" alt="m" />
                     </div>
                    

                     

                  

                 </Carousel>
                </div>
                {/* first carousal ends here */}
                
                
                
                <div className="btndiv my-4"  >
                  <span className="btn lastbtn btn-danger madimi-one-regular" onClick={()=>{Mycollections()}}> My Collections🔍</span>
                </div>

            </div>
            
        </div>
        
        </>
    )
}

export default Home;