// import axios from 'axios';
// import { useEffect ,useState} from 'react';
import Regularchild from "../Components/regularchild"
import Navbar from './Navbar';

const Regular =()=>{
      
    return(
        <>
                <Navbar page={2}></Navbar>
            <div className="container">
                    <div className="row">
                        <div className="heading my-5 madimi-one-regular" ><h1>Regular Circulation ₹</h1>
                        </div> 
                        <Regularchild ></Regularchild>
                    </div>
            </div>
        </>
    )
    }
    
    export default Regular;