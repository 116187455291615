import {BrowserRouter,Route,Routes} from 'react-router-dom';
import home from './Components/home';
import Mycollections from './Components/Mycollections';
import Antique from './Components/Antique';
import Specialcirculation from './Components/Specialcircul';
import Regular from './Components/Regular';
import Missmint from './Components/Missmint';
import test from './Components/test';
import test2 from './Components/testsecond';
import test3 from './Components/test3';
import Hyderabad from './Components/Hyderabad';
import Noida from './Components/Noida';
import Bombay from './Components/Bombay';
import Kolkatta from './Components/Kolkatta';

//                                                     //








 const Router =()=> {
    return (
        <>
        <BrowserRouter>
        <Routes>
            <Route path='/'Component={home} ></Route>
            <Route path='/mycollections'Component={Mycollections} ></Route>
            <Route path='/antique'Component={Antique} ></Route>
            <Route path='/special'Component={Specialcirculation} ></Route>
            <Route path='/regular'Component={Regular} ></Route>
            <Route path='/missmint'Component={Missmint} ></Route>
            <Route path='/Bombay'Component={Bombay} ></Route>
            <Route path='/Hyderabad'Component={Hyderabad} ></Route>
            <Route path='/Noida'Component={Noida} ></Route>
            <Route path='/Kolkatta'Component={Kolkatta} ></Route>



            <Route path='/test'Component={test} ></Route>
            <Route path='/test2'Component={test2} ></Route>
            <Route path='/test3'Component={test3} ></Route>


            {/* inter */}
            {/* <Route path='/'Component={Login} ></Route>
            <Route path='/login'Component={Login} ></Route> */}
{/* 
            <Route path='/dashboard'Component={Dashboard} ></Route>
            <Route path='/employe'Component={Employeelist} ></Route>
            <Route path='/employeedit'Component={Employeeedit} ></Route>
            <Route path='/home'Component={Home} ></Route> */}


        







        </Routes>
        </BrowserRouter>
        </>
    )

}
export default  Router;