import '../Styles/home.css'
import'../Styles/nav.css'
import {useNavigate}from "react-router-dom";

const Navbar=({page})=>{
    const navigate=useNavigate();
return <>
<div className="navbars" > 
<span className='backbtn'  onClick={()=>{if(page===2){navigate('/mycollections')}else navigate('/') }}  >←</span>
<div  id='navheading' >AKS Collections</div>




</div>
</>
}

export default Navbar;